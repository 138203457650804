function Header() {
  return (
    <header>
      <h1>
        Birdsy
        <span className="logo" role="img">
          🕊
        </span>
      </h1>
    </header>
  );
}

export default Header;
